.bet-form-container {
  margin: 20px auto;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.bet-form {
  max-width: 360px;
}

.bet-form-header .amount {
  font-size: 2em;
  color: black;
}

.bet-form-header .amount.minus {
  color: red;
}

.bet-form .form-button-container {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
  max-width: 430px;
}

.form-control {
  font-family: system-ui, sans-serif;
  font-size: 1.1rem;
  font-weight: bold;
  line-height: 1.1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

input[type="radio"] + div {
  margin: 0 5px;
  border: 2px solid white;
  display: flex;
  align-items: center;
  padding: 4px;
  width: 100px;
}

input[type="radio"]:disabled + div {
  /* color: gray; */
}

input[type="radio"]:disabled + div > img {
  filter: opacity(0.5);
}

input[type="radio"]:checked + div {
  border-color: var(--selected-game-color);
  background-color: var(--selected-game-color);
}

.disabled input[type="radio"]:checked + div {
  border-color: transparent;
  background-color: transparent;
}

.form-control + .form-control {
  margin-left: 1em;
  margin-right: 1em;
}

/*** GAME ***/

.bet-game {
  display: flex;
  padding: 10px 0;
  justify-content: center;
  border: none;
  background-color: "#fff";
  width: 100%;
}

.bet-game.disabled {
  color: #888;
}

.bet-logo {
  height: 40px;
}

.bet-odds {
  font-size: 0.8rem;
  text-align: center;
}

.bet-at {
  display: flex;
  align-items: center;
  font-size: 1.3em;
}

.form-dollar-span {
  position: absolute;
  right: -20px;
}

.bet-potential-win {
  position: absolute;
  right: -115px;
  color: gray;
  top: 2px;
  font-weight: normal;
  font-size: 0.9em;
}

.bet-amount-input {
  width: 120px;
  font-size: 1.5rem;
  padding-left: 25px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
