* {
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

:root {
  --main-header-color: #194078;
  --primary-color-light: #648cc4;
  --selected-game-color: rgba(255, 165, 0, 0.6);
  --color-secondary-btn-text: #24292f;
  --color-secondary-btn-bg: #f6f8fa;
  --color-btn-text: #fff;
  --color-btn-bg: #648cc4;
  --color-btn-border: rgba(27, 31, 36, 0.15);
}

html {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

body {
  background-color: rgba(239, 239, 239, 0.3);
}

#root {
  max-width: 1400px;
  margin: 0 auto;
}
