.pres-game-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/**** GAME ***/

.game-header-container {
  position: relative;
}

.game-header-background {
  position: relative;
}

.game-header-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  filter: opacity(30%);
  background-position: left, right;
}

.game-header {
  text-align: center;
}

.game-header-teams {
  display: inline-block;
  font-size: 1.15em;
}

.game-header-state {
  position: relative;
  top: -1px;
  font-size: 0.9em;
}

.game-header-quarter {
  font-weight: bold;
  padding: 3px 6px;
}

.game-header-play {
  font-style: italic;
}
.game-header-play span {
  background: white;
  border-radius: 5px;
}

.bets-container {
  display: flex;
  justify-content: center;
}

.bet-container {
  color: #001542;
  padding: 5px;
  background: white;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.16);
  text-align: center;
}

.bet-container:last-child {
  position: relative;
  left: -2px;
  box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.16);
  border-radius: 0 5px 5px 0;
}

.bet-container:first-child {
  border-radius: 5px 0 0 5px;
}

.bet-container.wrong {
  filter: grayscale(100%) opacity(75%);
}

.bet-pick {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pres-bet-logo {
  height: 40px;
}

.pres-potential {
  color: var(--primary-color-light);
}

.game-team-score {
  display: inline-block;
  background-color: var(--main-header-color);
  color: antiquewhite;
  margin: 0 5px;
  padding: 0;
  font-size: 1.1em;
  border-radius: 6px;
  width: 30px;
}
